import Component from 'gia/Component';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class Marquee extends Component {
  constructor(element) {
    super(element);
    this.ref = {
      innerMarquee:[]
    };
  }

  mount() {
    let that = this;

    let lengthString = this.ref.innerMarquee[0].innerText.replace(/<\/[^>]+(>|$)/g, "").length;
    let speed = 224 / lengthString;

    let width = this.ref.innerMarquee[0].offsetWidth;
    let ratio = width / window.innerWidth;
    // console.log(speed);
    gsap.to(this.ref.innerMarquee, {
      x: '-100%', // Move left by 50% of its width
      duration: 8 * ratio, // Duration of one complete cycle (adjust as needed)
      ease: 'linear', // Linear movement for smooth scrolling
      repeat: -1, // Repeat indefinitely
    });

  }


}