import Component from 'gia/Component';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class RevealingImage extends Component {
  constructor(element) {
    super(element);
    this.ref = {
      image: {}
    }
  }

  mount() {
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.gsap--revealing-images',
        start: "top bottom-=" + window.innerHeight / 4,
        end: "+500"
      }
    });

    tl.to(this.element, {
      scale:1,
      duration:1
    })
    tl.to(this.ref.image, {
      clipPath: 'inset(0%)',
      duration: 1,
      ease: 'power1.out',
      onComplete: () => {
        gsap.set(this.ref.image, {
          clipPath: 'none'
        });
      }
    },'-=1');
    tl.to(this.ref.image, {
      scale: 1,
      duration: 1
    }, '-=1');

  }
}