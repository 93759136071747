import Component from 'gia/Component';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother} from 'gsap/ScrollSmoother';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollSmoother)



export default class Header extends Component {
  constructor(element) {
    super(element);
    this.ref = {
      logo: {},
      clipPathElement: {}
    };
  }

  mount() {
    ScrollSmoother.create({
      smooth: 0.1,
      effects: false,
      normalizeScroll: true,
      ignoreMobileResize: false,
    });


    let that = this;


    // let logoTimeline = gsap.timeline({
    //   duration: 0.3,
    //
    //   scrollTrigger: {
    //     trigger: 'body',
    //     start: 'top top',
    //     end: '+=500px',
    //     toggleActions: 'play reverse play reverse'
    //   }
    // });
    // logoTimeline.fromTo(this.ref.logo, {
    //   width: '100%'
    // }, {
    //   width:'276px',
    //   duration:0.5
    // });
    // logoTimeline.to(this.ref.clipPathElement, {
    //   duration:0.5,
    //   clipPath: 'ellipse(100% 100% at 50% 50%)',
    // }, '-=0.5');
    //
    //
    //
    // gsap.to(this.element, {
    //   scrollTrigger: {
    //     trigger: 'body',
    //     start: 'top top',
    //     end:'+=500px',
    //     pin: true,
    //     invalidateOnRefresh: true,
    //   }
    // });
    let lengthAnim = window.innerHeight
    if (window.innerWidth < 576) {
      lengthAnim = lengthAnim / 2;
    }

    let logoTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.element,
        start: 'top top-=1',
        end: '+=' + lengthAnim,
        pin: true,
        scrub: 0.4
        // toggleActions: 'play reverse play reverse'
      }
    });
    logoTimeline.to(this.ref.clipPathElement, {
      duration: 1,
      clipPath: 'ellipse(80% 100% at 50% 50%)',
    });

    let width = 276;
    if (window.innerWidth < 576) {
      width = 164;
    }

    logoTimeline.fromTo(this.ref.logo, {
      scale: 1,
      paddingTop:'20px',
      paddingBottom:'20px',
    }, {
      // width: width,
      scale: 0.4,
      paddingTop:'5px',
      paddingBottom:'5px',
      duration: 1
    }, '-=1');




    // ScrollTrigger.normalizeScroll({ allowNestedScroll: true, type: "touch,scroll,pointer" });
    // ScrollTrigger.addEventListener("scrollStart", () => gsap.ticker.add(ScrollTrigger.update));
    // ScrollTrigger.addEventListener("scrollEnd", () => gsap.ticker.remove(ScrollTrigger.update));

    // gsap.to(this.element, {
    //   scrollTrigger: {
    //     trigger: 'body',
    //     start: 'top top',
    //     end:'+=500px',
    //     pin: true,
    //     invalidateOnRefresh: true,
    //   }
    // });


  }


}