import Header from './Header';
import Marquee from './Marquee.js';
import TextReveal from './TextReveal.js';
import Video from './Video.js';
import HomeVideo from './HomeVideo.js';
import ImageZoom from './ImageZoom.js';
import RevealingImage from './RevealingImage.js';
import QualityIngredients from './QualityIngredients.js';
import TranslatingImages from './TranslatingImages.js';
import TranslatingImagesMobile from './TranslatingImagesMobile.js';
import PinSection from './PinSection.js';
import Patties from './Patties.js';
import TextRevealV2 from './TextRevealV2.js'
import FixedHeader from './FixedHeader.js';

const components = {
  Header,
  Marquee,
  TextReveal,
  Video,
  HomeVideo,
  ImageZoom,
  RevealingImage,
  QualityIngredients,
  TranslatingImages,
  TranslatingImagesMobile,
  PinSection,
  Patties,
  TextRevealV2,
  FixedHeader
}

window.onbeforeunload = function () {
  window.scrollTo(0, 0);
}
export default components;
