import Component from 'gia/Component';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class TextReveal extends Component {
  constructor(element) {
    super(element);
    this.ref = {
      line: [],
      lineParent:[]
    };
  }

  mount() {
    let that = this;


    this.ref.lineParent.forEach((line) => {
      gsap.fromTo(line, {
        x: '-100%'
      }, {
        x: '0%',
        scrollTrigger: {
          trigger: line,
          start: "top bottom",
          end: "top center",
          scrub: 1,
          markers: false,
          ease: 'power4.inOut'
        }
      })
    });

    this.ref.line.forEach((line) => {
      gsap.fromTo(line, {
        x: '100%'
      }, {
        x: '0%',
        scrollTrigger: {
          trigger: line,
          start: "top bottom",
          end: "top center",
          scrub: 1,
          markers: false,
          ease: 'power4.inOut'
        }
      })
    });
  }


}