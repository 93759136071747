import Component from 'gia/Component';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class Patties extends Component {
  constructor(element) {
    super(element);
    this.ref = {
      patties: []
    };
  }

  mount() {
    let that = this;
    gsap.fromTo(this.ref.patties, {
      opacity:0,
      y:'100%'
    },{
      duration: 1.2,
      opacity:1,
      y:'0%',
      ease: 'power2.out',
      stagger: 0.1,
      scrollTrigger: {
        trigger: this.element,
        start: 'top bottom-=15%',
      }
    });


  }


}