import Component from 'gia/Component';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class PinSection extends Component {
  constructor(element) {
    super(element);

  }

  mount() {
    gsap.to(this.element, {
      scale: 1, // Move left by 50% of its width
      scrollTrigger: {
        trigger: this.element,
        start: 'bottom bottom',
        pinSpacing: false,
        pin: true,
        invalidateOnRefresh: false,
      },
    });
  }
}