import Component from 'gia/Component';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class FixedHeader extends Component {
  constructor(element) {
    super(element);
    this.ref = {
      logo: {}
    };
  }

  mount() {
    let that = this;

    const showAnim = gsap.fromTo(this.element, {
      yPercent: -100
    },{
      yPercent: 0,
      paused: true,
      duration: 0.2
    });

    ScrollTrigger.create({
      start: "top top",
      end: "max",
      markers: false,
      onUpdate: (self) => {
        if ((self.direction === -1) && (window.pageYOffset > window.innerHeight * 2)) {
          showAnim.play();
        } else {
          showAnim.reverse();
        }
      }
    });


  }


}