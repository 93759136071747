import 'lazysizes';

import loadComponents from 'gia/loadComponents';



import components from './components';
window.loadComponents = loadComponents;
window.components = components;
loadComponents(components);
