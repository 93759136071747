import Component from 'gia/Component';
import YouTubePlayer from 'youtube-player';
import Player from '@vimeo/player';

export default class Video extends Component {
  constructor(element) {
    super(element);
    this.options = {
      video: "0",
      type: "youtube",
      autoplay: 0,
      hero: false
    };
  }

  mount() {
    let player;
    let videoId = this.options.video;
    let videoType = this.options.type;
    let autoplay = this.options.autoplay;
    let controls = this.options.controls;
    let hero = this.options.hero;

    if (videoType == 'youtube') {

      player = YouTubePlayer(this.element, {
        videoId: videoId,
        playerVars: {
          rel: 0,
          modestbranding: 1,
          autoplay: autoplay,
          controls: controls,
          loop: autoplay,
          playsinline: 1,
          loop: hero ? 1 : 0
        }
      });
      let stateNames = {
        '-1': 'unstarted',
        0: 'ended',
        1: 'playing',
        2: 'paused',
        3: 'buffering',
        5: 'video cued'
      };

      if (hero) {
        player.mute();
        player.on('stateChange', function(event) {
          const swiper = document.querySelector('.swiper-container--hero').swiper;
          if ((event.data == 3) || (event.data == 1)) {

            swiper.autoplay.stop();
          } else {

            swiper.autoplay.start();
          }
        });
      }
    } else {
      let options;
      if (autoplay) {
        options = {
          id: videoId,
          width: 800,
          controls: true,
          autoplay: autoplay,
          background: !controls,
          autopause: false,
          muted: true,
          playsinline: true,
          portrait: true
        };
      } else {
        options = {
          id: videoId,
          width: 800
        };
      }

      player = new Player(this.element, options);

    }
  }

}