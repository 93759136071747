import Component from 'gia/Component';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class TranslatingImagesMobile extends Component {
  constructor(element) {
    super(element);
    this.ref = {
      imageFirst: {},
      imageLast :{}
    }
  }

  mount() {
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: this.element,
        start: "top center"
      },

    });

    tl.to(this.ref.imageLast, {
      x:0,
      duration:0.8,
      ease: 'power1.out'
    })
    tl.to(this.ref.imageFirst, {
      x:0,
      duration: 0.8
    }, '-=0.8');
  }
}