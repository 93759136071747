import Component from 'gia/Component';


import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
export default class Video extends Component {
  constructor(element) {
    super(element);
    this.ref = {
      clipPathElement: {}
    }
  }

  mount() {
    gsap.to(this.ref.clipPathElement, {
      duration: 1,

      clipPath: 'ellipse(100% 100% at 50% 50%)',
      ease: 'power2.inOut',
      scrollTrigger: {
        trigger: 'body',
        start: 'top top-=50',
        end: 'bottom center',
        toggleActions: 'play reverse play reverse'
      },
    });

    gsap.to(this.element, {
      duration: 1,
      scrollTrigger: {
        pin: true,
        trigger: this.element,
        scrub: true,
        start: 'top top',
        end: '+=100',
        pinSpacing: false,
      },
    });


  }

}