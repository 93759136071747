import Component from 'gia/Component';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class QualityIngredients extends Component {
  constructor(element) {
    super(element);
    this.ref = {
      image: {}
    };
    this.options = {
      translate: false
    }
  }

  mount() {
    let tl = gsap.timeline({
      repeat: -1,
      scrollTrigger: {
        trigger: this.element,
        start: "top bottom",
        end: "+500"
      }
    });

    tl.to(this.element, {
      rotate: 360,
      duration: 29,
      ease: 'linear'
    });

    if (this.options.translate) {

      gsap.to(this.element, {
        y: -100,
        ease: 'linear',
        scrollTrigger: {
          trigger: this.element,
          start: "top bottom",
          end: "top top",
          scrub: true,
        }
      })
    }

  }
}