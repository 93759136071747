import Component from 'gia/Component';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class ImageZoom extends Component {
  constructor(element) {
    super(element);

  }

  mount() {
    gsap.to(this.element, {
      scale: 1, // Move left by 50% of its width
      duration:0.7,
      scrollTrigger: {
        trigger: this.element,
        scrub: false,
        start: 'top center+=25%',
        end: 'top top',
        toggleActions: 'play none none reverse'
      }
    });
  }
}